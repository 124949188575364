import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  DialogContentText,
  ImageList,
  ImageListItem,
  Slide,
} from "@mui/material";
import theme from "../theme/MuiTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImagesDialog = ({ onClose, open, title, images, description }) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <DialogTitle variant="h5" color={theme.palette.primary.main}>
        {title}
      </DialogTitle>
      <DialogContentText sx={{ marginLeft: 3, marginRight: 3 }}>
        {description}
      </DialogContentText>
      <DialogContent>
        <ImageList
          sx={{ width: 850, height: 650 }}
          variant="woven"
          cols={3}
          gap={8}
        >
          {images.map((image, index) => (
            <ImageListItem key={index} sx={{ marginBottom: 5 }}>
              <img src={image} alt={image} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      </DialogContent>
      <DialogActions>
        <Button style={{ textTransform: "none" }} onClick={onClose}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImagesDialog;
