import Typography from "@mui/material/Typography";
import React from "react";

const MainTitle = ({ text, fontSize, number }) => {
  return (
    <div>
      <Typography variant="h1" fontSize={fontSize} align="center">
        {` ${number ? number + " " + text : text}`}
      </Typography>
    </div>
  );
};

export default MainTitle;
