import React, { useEffect, useState } from "react";
import app from "../../firebase/config/config";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { Container, Grid, Grow, CircularProgress } from "@mui/material";
import MainTitle from "../../components/mainTitle/index";
import CustomCard from "../../components/CustomCard";
import artiste from "../../images/artiste/artiste.png";

const db = getFirestore(app);

const Locations = () => {
  const [locations, setLocations] = useState(null);

  useEffect(() => {
    getLocations();
  }, []);

  async function getLocations() {
    const querySnapshot = await getDocs(collection(db, "locations"));
    const _locations = [];
    querySnapshot.forEach((doc) => {
      _locations.push(doc.data());
    });
    setLocations(_locations);
  }
  return (
    <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 8 }}>
      <MainTitle text={"Locations Costumes"} fontSize={50} />
      <Grid
        container
        mt={5}
        mb={locations ? 0 : 100}
        spacing={4}
        justifyContent="center"
      >
        {locations ? (
          locations.map((location, index) => (
            <Grow key={`${location.title}-${index}`} in timeout={index * 500}>
              <Grid item xs={12} sm={4} md={3} key={index}>
                <CustomCard
                  title={location.title}
                  image={location?.image ? location.image : artiste}
                  price={location.price}
                  description={location.description}
                  type={location.type}
                  category={location.category}
                  size={location.size}
                  colors={location.colorPrimary}
                />
              </Grid>
            </Grow>
          ))
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Container>
  );
};

export default Locations;
