import { ThemeProvider } from "@mui/material/styles";
import './App.css';
import theme from "./theme/MuiTheme";
import Navigation from "./routes/Navigation";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Navigation />
    </ThemeProvider>
  );
}

export default App;
