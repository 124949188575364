import { Checkroom, Class, DoNotDisturbAlt, Info } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import theme from "../theme/MuiTheme";
import ImagesDialog from "./ImagesDialog";

const CustomCard = ({
  uniqueModel,
  image,
  title,
  price,
  description,
  type,
  category,
  size,
  colors,
  stars,
  handleRatingDetailsDialogOpen,
}) => {
  const [openImagesDialog, setOpenImagesDialog] = React.useState(null);
  return (
    <>
      <Card
        raised
        sx={{
          width: "-webkit-fill-available",
          minHeight: 865,
          padding: "20px",
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <CardMedia
          sx={{
            height: 500,
            borderRadius: 5,
            cursor: "pointer",
          }}
          component="img"
          src={image[0]}
          alt="artiste"
          onClick={() =>
            setOpenImagesDialog({
              title: title,
              description: description,
              image: image,
            })
          }
        />
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              fontWeight={"bold"}
              variant="h6"
              sx={{ marginBottom: uniqueModel ? 0 : 2 }}
            >
              {title}
            </Typography>
            {price?.threeDays ? (
              <Stack direction="column" justifyContent="space-around">
                <Typography
                  fontWeight={"bold"}
                  fontSize={14}
                  color={theme.palette.primary.main}
                >
                  {price.threeDays.toFixed(2) + " €"}
                </Typography>
                <Typography fontWeight={"medium"} fontSize={12}>
                  Sur 3 jours
                </Typography>
              </Stack>
            ) : (
              <Typography
                fontWeight={"bold"}
                fontSize={14}
                color={theme.palette.primary.main}
              >
                {price && price ? (
                  price.toFixed(2) + " €"
                ) : (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={handleRatingDetailsDialogOpen}
                  >
                    <Rating readOnly value={stars} size="small" />
                  </div>
                )}
              </Typography>
            )}

            {price?.weekly && (
              <Stack direction="column" justifyContent="space-around">
                <Typography
                  fontWeight={"bold"}
                  fontSize={14}
                  color={theme.palette.primary.main}
                >
                  {price.weekly.toFixed(2) + " €"}
                </Typography>
                <Typography fontWeight={"medium"} fontSize={12}>
                  à la semaine
                </Typography>
              </Stack>
            )}
          </Stack>
          {uniqueModel && (
            <Typography
              fontSize={12}
              color={theme.palette.primary.main}
              align="left"
            >
              Modèle unique
            </Typography>
          )}
          <List>
            <ListItem>
              <ListItemIcon>
                <Checkroom fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Type" secondary={type} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Class fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Categorie"
                secondary={
                  typeof category === "string"
                    ? category
                    : category?.join(" / ")
                }
              />
            </ListItem>
          </List>
          <Stack mb={1} direction={"row"} spacing={2} alignItems="center">
            <Typography fontWeight={"bold"} variant="h6">
              Description
            </Typography>
            <Tooltip
              title={
                <Typography fontSize={14} variant="body1">
                  {description}
                </Typography>
              }
            >
              <Info sx={{ color: theme.palette.primary.main }} />
            </Tooltip>
          </Stack>
          <Stack mt={2} direction={"row"} justifyContent="space-between">
            <Stack direction={"column"} spacing={1}>
              <Typography fontWeight={"bold"} fontSize={14}>
                Taille
              </Typography>
              {size ? (
                typeof size === "string" ? (
                  <Typography
                    sx={{ color: theme.palette.secondary.light, fontSize: 12 }}
                  >
                    {size}
                  </Typography>
                ) : (
                  <Stack direction={"row"} spacing={1}>
                    {size?.map((item, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color: theme.palette.secondary.light,
                          fontSize: 12,
                        }}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Stack>
                )
              ) : (
                <DoNotDisturbAlt
                  fontSize="small"
                  sx={{ color: theme.palette.secondary.light }}
                />
              )}
            </Stack>
            <Stack spacing={1}>
              <Typography fontWeight={"bold"} fontSize={14}>
                Couleurs
              </Typography>
              <Stack spacing={0.5} direction={"row"}>
                {colors ? (
                  colors.map((color, index) => (
                    <Stack
                      key={index}
                      sx={{
                        width: 12,
                        height: 12,
                        borderRadius: 10,
                        border: "0.1px solid lightgrey",
                        backgroundColor: color,
                      }}
                    />
                  ))
                ) : (
                  <DoNotDisturbAlt
                    fontSize="small"
                    sx={{ color: theme.palette.secondary.light }}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      {openImagesDialog && (
        <ImagesDialog
          onClose={() => setOpenImagesDialog(null)}
          open={openImagesDialog !== null}
          title={openImagesDialog.title}
          images={openImagesDialog.image}
          description={openImagesDialog.description}
        />
      )}
    </>
  );
};

export default CustomCard;
