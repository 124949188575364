import { IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../../../theme/MuiTheme";

const MenuNavigation = () => {
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const links = React.useMemo(
    () => [
      { name: "Créations", route: "creations" },
      { name: "Locations", route: "locations" },
      { name: "L'artiste", route: "artiste" },
      { name: "Shooting photos", route: "shooting" },
      { name: "Contact", route: "contact" },
    ],
    []
  );

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {links.map((link, index) => (
          <MenuItem
            onClick={() => {
              navigate(`/${link.route}`);
              setAnchorEl(null);
            }}
            key={index}
          >
            {link.name}
          </MenuItem>
        ))}
        <MenuItem>
          <a
            href="https://feeriescouture.sumupstore.com/"
            target="blank"
            style={{
              textDecoration: "none",
              color: theme.palette.secondary.dark,
              fontFamily: "Big Caslon",
            }}
          >
            Boutique en ligne
          </a>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MenuNavigation;
