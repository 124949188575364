import React from "react";
import {
  CircularProgress,
  Container,
  Grid,
  Grow,
  MenuItem,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import MainTitle from "../../components/mainTitle";
import { collection, getDocs } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import app from "../../firebase/config/config";
import CustomCard from "../../components/CustomCard";
import artiste from "../../images/artiste/artiste.png";
import CustomDialog from "../../components/CustomDialog";
import { Stack } from "@mui/system";
import theme from "../../theme/MuiTheme";

const db = getFirestore(app);

const Creations = () => {
  const [creations, setCreations] = React.useState(null);
  const [creationType, setCreationType] = React.useState("");
  const [isDetailsRatingDialogOpened, setIsDetailsRatingDialogOpened] =
    React.useState(false);
  React.useEffect(() => {
    getCreations();
  }, []);

  async function getCreations() {
    const querySnapshot = await getDocs(collection(db, "creations"));
    const _creations = [];
    querySnapshot.forEach((doc) => {
      _creations.push(doc.data());
    });
    setCreations(_creations);
  }

  function getCreationTypes() {
    if (creations) {
      const types = creations.map((creation) => creation.type);
      const uniqueArray = Array.from(new Set(types));
      return uniqueArray.filter((item) => item !== undefined);
    }
  }

  const ratingList = React.useMemo(
    () => [
      { label: "moins de 200€", value: 1 },
      { label: "de 200 à 599€", value: 2 },
      { label: "de 600 à 999€", value: 3 },
      { label: "de 1000 à 1499€", value: 4 },
      { label: "à partir de 1500€", value: 5 },
    ],
    []
  );

  function renderRatingDetails() {
    return ratingList.map((rating, index) => (
      <Stack
        key={index}
        direction={"row"}
        spacing={2}
        alignItems="center"
        pl={20}
        mb={0.5}
      >
        <Rating readOnly value={rating.value} size="small" />
        <Typography color={theme.palette.primary.light}>
          {rating.label}
        </Typography>
      </Stack>
    ));
  }

  return (
    <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 8 }}>
      <MainTitle
        text={
          creations?.filter((creation) =>
            creationType === ""
              ? creation.type !== creationType
              : creation.type === creationType
          ).length <= 1
            ? "Création"
            : "Créations"
        }
        fontSize={50}
        number={
          creations?.filter((creation) =>
            creationType === ""
              ? creation.type !== creationType
              : creation.type === creationType
          ).length
        }
      />
      <Grid container justifyContent={"space-around"} spacing={2} mt={1}>
        <Grid item xs={6} lg={4}>
          <TextField
            value={creationType}
            size="small"
            fullWidth
            select
            label="Type"
            onChange={(event) => setCreationType(event.target.value)}
          >
            <MenuItem value="">Tous</MenuItem>
            {getCreationTypes()?.map((creation, index) => (
              <MenuItem key={index} value={creation}>
                {creation}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid
        container
        mt={5}
        mb={creations ? 0 : 100}
        spacing={4}
        justifyContent="center"
      >
        {creations ? (
          creations
            .filter((creation) =>
              creationType === ""
                ? creation.type !== creationType
                : creation.type === creationType
            )
            .map((creation, index) => (
              <Grow key={`${creation.title}-${index}`} in timeout={index * 500}>
                <Grid item xs={12} sm={4} md={3} key={index}>
                  <CustomCard
                    title={creation.title}
                    uniqueModel={creation.uniqueModel}
                    image={creation?.image ? creation.image : artiste}
                    price={creation.price}
                    description={creation.description}
                    type={creation.type}
                    category={creation.category}
                    size={creation.size}
                    colors={creation.colorPrimary}
                    stars={creation.stars}
                    handleRatingDetailsDialogOpen={() =>
                      setIsDetailsRatingDialogOpened(true)
                    }
                  />
                </Grid>
              </Grow>
            ))
        ) : (
          <CircularProgress />
        )}
      </Grid>
      {isDetailsRatingDialogOpened && (
        <CustomDialog
          open={isDetailsRatingDialogOpened}
          onClose={() => setIsDetailsRatingDialogOpened(false)}
          title="Tranches de prix"
          content={renderRatingDetails()}
          textContent={
            "Une idée en tête ? N'hésitez plus ! Contactez-moi ! Je mettrai mes crayons à votre service pour réaliser le croquis de votre imagination accompagné d'un devis. Mon objectif : cerner au plus juste vos attentes afin de réaliser VOTRE projet en adéquation avec votre budget. Réalisons ensemble le costume de vos rêves !"
          }
        />
      )}
    </Container>
  );
};

export default Creations;
