import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import theme from "../../theme/MuiTheme";
import logo from "../../images/logo/logo2.svg";
import {
  Facebook,
  Home,
  Instagram,
  Pinterest,
  Room,
  ShoppingCart,
} from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";

const Footer = () => {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const renderListItem = (icon, text) => {
    return (
      <ListItem>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  };

  return (
    <div style={{ marginTop: !mobileView ? 150 : 650 }}>
      <Grid
        spacing={3}
        container
        justifyContent="space-around"
        alignContent="center"
        sx={{
          backgroundColor: theme.palette.secondary.main,
          height: "100%",
          boxShadow: "0px -3px 6px " + theme.palette.primary.dark,
        }}
      >
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          item
          xs={12}
          sm={4}
          direction={{ xs: "column", sm: "row" }}
        >
          <img src={logo} width={108} height={108} alt="logo2" />
          <Typography>
            Ouvert du lundi au samedi sur rendez-vous uniquement
          </Typography>
          <Typography>Fermé le dimanche</Typography>
        </Grid>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          item
          xs={12}
          sm={4}
        >
          <Facebook sx={{ fontSize: 40, color: "#3B5998" }} />
          <Instagram sx={{ fontSize: 40, color: "#E1306C" }} />
          <Pinterest sx={{ fontSize: 40, color: "#C8232C" }} />
        </Grid>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          item
          xs={12}
          sm={4}
        >
          <List disablePadding>
            {renderListItem(
              <Home sx={{ color: theme.palette.primary.main }} />,
              "Féeries Couture"
            )}
            {renderListItem(
              <Room sx={{ color: theme.palette.primary.main }} />,
              "6 Chemin du Roc, 11400 Villeneuve-la-Comptal, France"
            )}
            {renderListItem(
              <ShoppingCart sx={{ color: theme.palette.primary.main }} />,
              "Voir ma boutique"
            )}
          </List>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ height: 60, backgroundColor: theme.palette.primary.main }}
      ></Grid>
    </div>
  );
};

export default Footer;
