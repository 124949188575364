import { Grid } from "@mui/material";
import React from "react";
import MainTitle from "../../components/mainTitle";
import FormContact from "./components/formContact";

const Contact = () => {
  return (
    <Grid mt={4}>
      <MainTitle text="Contact" fontSize={50} />
      <FormContact />
    </Grid>
  );
};

export default Contact;
