import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  DialogContentText,
  Paper,
} from "@mui/material";
import React from "react";
import theme from "../theme/MuiTheme";

const CustomDialog = ({ onClose, open, title, content, textContent }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <Paper
        elevation={3}
        sx={{ margin: 2, backgroundColor: theme.palette.primary.main }}
      >
        <DialogContent>{content}</DialogContent>
      </Paper>
      {textContent && (
        <DialogContentText sx={{ padding: 3 }}>{textContent}</DialogContentText>
      )}
      <DialogActions>
        <Button style={{ textTransform: "none" }} onClick={onClose}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
