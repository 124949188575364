import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import theme from "../../theme/MuiTheme";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo/logo.svg";
import MenuNavigation from "./components/menuNavigation";

const Header = () => {
  let navigate = useNavigate();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayLink = (text, url) => {
    return (
      <Link
        style={{
          textDecoration: "none",
          color: theme.palette.secondary.dark,
          fontFamily: "Big Caslon",
        }}
        to={url}
      >
        {text}
      </Link>
    );
  };

  const displayLogo = () => {
    return (
      <img
        src={logo}
        width={207}
        height={99}
        alt="logo Féeries Couture"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
    );
  };

  return (
    <header>
      {!mobileView ? (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.secondary.main,
            height: 101,
            boxShadow: "0px 3px 6px " + theme.palette.primary.dark,
          }}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid
            item
            xs={3}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            {displayLink("Créations", "creations")}
            {displayLink("Locations", "locations")}
            <a
              href="https://feeriescouture.sumupstore.com/"
              target="blank"
              style={{
                textDecoration: "none",
                color: theme.palette.secondary.dark,
                fontFamily: "Big Caslon",
              }}
            >
              Boutique en ligne
            </a>
          </Grid>

          <Grid item xs={2} sx={{ display: "flex", justifyContent: "center" }}>
            {displayLogo()}
          </Grid>
          {displayLink("L'artiste", "artiste")}
          {displayLink("Shooting photos", "shooting")}
          {displayLink("Contact", "contact")}
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.secondary.main,
            height: 101,
            boxShadow: "0px 3px 6px " + theme.palette.primary.dark,
          }}
          justifyContent="space-evenly"
          alignItems="center"
        >
          {displayLogo()}
          <MenuNavigation />
        </Grid>
      )}
    </header>
  );
};

export default Header;
