import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import Home from "../pages/home";
import Contact from "../pages/contact/index";
import Artist from "../pages/artist";
import Creations from "../pages/creations/index";
import Shooting from "../pages/shooting/index";
import Locations from "../pages/locations/index";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/artiste" element={<Artist />} />
        <Route path="/creations" element={<Creations />} />
        <Route path="/shooting" element={<Shooting />} />
        <Route path="/locations" element={<Locations />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Navigation;
