import React, { useMemo, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import MainTitle from "../../components/mainTitle";
import { collection, getDocs } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import app from "../../firebase/config/config";
import theme from "../../theme/MuiTheme";
import ImageIcon from "@mui/icons-material/Image";
import CustomCarousel from "../../components/CustomCarousel";

const db = getFirestore(app);

const Shooting = () => {
  const [shootings, setShootings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imagesShooting, setImagesShooting] = useState({
    open: false,
    list: [],
    title: "",
  });
  const [value, setValue] = React.useState("one");

  const videos = useMemo(
    () => [
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/0knlibZBqOM?si=AWnWvbNWIagcHvyF"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      />,
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/gvceddIcESo?si=fJeDKXYCaQAggOH1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      />,
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/2GRV9Ii7oLk?si=Swz1kdZteZZjjTlO"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      />,
    ],
    []
  );

  React.useEffect(() => {
    getShootings();
  }, []);

  async function getShootings() {
    try {
      const querySnapshot = await getDocs(collection(db, "shooting"));
      const _creations = [];
      querySnapshot.forEach((doc) => {
        _creations.push(doc.data());
      });
      setShootings(_creations);
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  function handleOpenImagesShootingModal(list, title) {
    setImagesShooting({ open: true, list: list, title: title });
  }

  function handleCloseImagesShottingModal() {
    setImagesShooting({ open: false, list: [], title: "" });
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 8 }}>
      <MainTitle text={"Shooting"} fontSize={50} />
      <Box
        mt={4}
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab value="one" label="Photos" />
          <Tab value="two" label="Vidéo" />
        </Tabs>
      </Box>
      {value === "one" && (
        <Grid
          container
          mt={5}
          mb={shootings.length > 0 ? 0 : 100}
          spacing={10}
          justifyContent="center"
        >
          {loading ? (
            <CircularProgress />
          ) : (
            shootings.map((shooting) => (
              <Grid item key={shooting.id} xs={12} md={6}>
                <Card
                  sx={{
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleOpenImagesShootingModal(
                      shooting.images,
                      shooting.title
                    )
                  }
                >
                  <CardHeader
                    title={shooting.title}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                    }}
                    action={
                      <IconButton
                        sx={{ color: "white" }}
                        aria-label="images"
                        onClick={() =>
                          handleOpenImagesShootingModal(
                            shooting.images,
                            shooting.title
                          )
                        }
                      >
                        <ImageIcon fontSize="large" />
                      </IconButton>
                    }
                  />
                  <CardMedia
                    component="img"
                    height="450"
                    image={shooting.images[0]}
                    alt={shooting.title}
                  />
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      )}
      {value === "two" && (
        <Grid
          container
          mt={5}
          mb={shootings.length > 0 ? 0 : 100}
          spacing={10}
          justifyContent="center"
        >
          {videos.map((video, index) => (
            <Grid key={`video-${index}`} item xs={12} sm={6} md={4}>
              {video}
            </Grid>
          ))}
        </Grid>
      )}
      <Dialog
        open={imagesShooting.open}
        onClose={handleCloseImagesShottingModal}
        fullScreen
      >
        <DialogTitle>{imagesShooting.title}</DialogTitle>
        <DialogContent>
          <CustomCarousel
            images={imagesShooting.list.map((item) => ({
              label: imagesShooting.title,
              imgPath: item,
            }))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImagesShottingModal}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Shooting;
