import {
  Button,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React, { useMemo, useRef, useState } from "react";
import MainTitle from "../../../../components/mainTitle";
import theme from "../../../../theme/MuiTheme";
import emailjs from "@emailjs/browser";
import { emailValidation } from "../../../../utils/validators";
import CustomizedSnackbars from "../snackBar/index";
import { emailJs } from "../../../../utils/emailJs";

const FormContact = () => {
  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    address: "",
    postal: "",
    city: "",
    phoneNumber: "",
    email: "",
    objectContact: "",
    messageText: "",
  });
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    phoneNumber: "",
    email: "",
    objectContact: "",
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [severitySnackBar, setSeveritySnackBar] = useState("success");
  const [errorMessage, setErrorMessage] = useState("");

  const objectOptions = useMemo(
    () => ["Devis création", "Location", "Partenariat", "Autres"],
    []
  );

  const emailJsApiKey = emailJs.apiKey;

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      emailValidation(formValues.email) &&
      formValues.lastname !== "" &&
      formValues.firstname !== "" &&
      formValues.phoneNumber !== "" &&
      formValues.objectContact !== ""
    ) {
      setErrors({
        ...errors,
        firstname: "",
        lastname: "",
        phoneNumber: "",
        email: "",
        objectContact: "",
      });

      emailjs
        .sendForm(
          "service_vmeg3uf",
          "template_z9g50zo",
          form.current,
          emailJsApiKey
        )
        .then(
          (result) => {
            setOpenSnackBar(true);
            setSeveritySnackBar("success");
          },
          (error) => {
            setOpenSnackBar(true);
            setSeveritySnackBar("error");
            setErrorMessage(error.text);
          }
        );
    } else {
      setErrors({
        ...errors,
        firstname: formValues.firstname === "" ? "Renseignez votre prénom" : "",
        lastname: formValues.lastname === "" ? "Renseignez votre nom" : "",
        phoneNumber:
          formValues.phoneNumber === ""
            ? "Renseignez un numéro de téléphone"
            : "",
        email: "Renseignez un email valide",
        objectContact:
          formValues.objectContact === ""
            ? "Renseigner un motif de contact"
            : "",
      });
    }
  };

  const handleLastnameChange = (event) => {
    setFormValues({ ...formValues, lastname: event.target.value });
  };

  const handleFirstnameChange = (event) => {
    setFormValues({ ...formValues, firstname: event.target.value });
  };

  const handleAddressChange = (event) => {
    setFormValues({ ...formValues, address: event.target.value });
  };

  const handlePostalChange = (event) => {
    setFormValues({ ...formValues, postal: event.target.value });
  };

  const handleCityChange = (event) => {
    setFormValues({ ...formValues, city: event.target.value });
  };

  const handlePhoneNumerChange = (event) => {
    setFormValues({ ...formValues, phoneNumber: event.target.value });
  };

  const handleEmailChange = (event) => {
    setFormValues({ ...formValues, email: event.target.value });
  };

  const handleObjectContactChange = (event) => {
    setFormValues({ ...formValues, objectContact: event.target.value });
  };

  const handleMessageText = (event) => {
    setFormValues({ ...formValues, messageText: event.target.value });
  };

  return (
    <Container
      sx={{
        marginBottom: 8,
        marginTop: 5,
        paddingTop: 5,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: "0px 3px 6px " + theme.palette.primary.dark,
      }}
      maxWidth="md"
    >
      <MainTitle text="Formulaire" fontSize={25} />
      <form ref={form} onSubmit={sendEmail}>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          height="100%"
          pt={8}
          spacing={2}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
            sm={6}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <TextField
                type="text"
                label="Nom"
                name="user_last_name"
                variant="outlined"
                value={formValues.lastname}
                size="small"
                color="primary"
                onChange={handleLastnameChange}
                error={errors.lastname !== ""}
                helperText={errors.lastname !== "" && errors.lastname}
              />
              <TextField
                type="text"
                label="Prénom"
                name="user_first_name"
                variant="outlined"
                value={formValues.firstname}
                size="small"
                color="primary"
                onChange={handleFirstnameChange}
                error={errors.firstname !== ""}
                helperText={errors.firstname !== "" && errors.firstname}
              />
              <TextField
                type="address"
                label="Adresse"
                name="user_address"
                variant="outlined"
                value={formValues.address}
                size="small"
                color="primary"
                onChange={handleAddressChange}
              />
              <TextField
                type="address"
                label="Code postal"
                name="user_postal"
                variant="outlined"
                value={formValues.postal}
                size="small"
                color="primary"
                onChange={handlePostalChange}
              />
              <TextField
                type="address"
                label="Ville"
                name="user_city"
                variant="outlined"
                value={formValues.city}
                size="small"
                color="primary"
                onChange={handleCityChange}
              />
              <TextField
                type="tel"
                label="N° Téléphone"
                name="user_phone_number"
                variant="outlined"
                value={formValues.phoneNumber}
                size="small"
                color="primary"
                onChange={handlePhoneNumerChange}
                error={errors.phoneNumber !== ""}
                helperText={errors.phoneNumber !== "" && errors.phoneNumber}
              />
              <TextField
                required
                type="mail"
                label="Email"
                name="user_email"
                variant="outlined"
                value={formValues.email}
                size="small"
                color={
                  emailValidation(formValues.email) ? "success" : "primary"
                }
                onChange={handleEmailChange}
                error={
                  formValues.email !== "" && !emailValidation(formValues.email)
                }
                helperText={
                  formValues.email !== "" &&
                  !emailValidation(formValues.email) &&
                  errors.email
                }
              />
              <TextField
                fullWidth
                select
                label="Objet"
                name="subject"
                variant="outlined"
                defaultValue={objectOptions[0]}
                value={formValues.objectContact}
                size="small"
                color="primary"
                onChange={handleObjectContactChange}
                error={errors.objectContact !== ""}
                helperText={errors.objectContact !== "" && errors.objectContact}
              >
                {objectOptions.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="flex-start"
            item
            xs={12}
            sm={6}
          >
            <TextField
              fullWidth
              label="Votre message..."
              name="message"
              multiline
              type="text"
              value={formValues.messageText}
              color="primary"
              rows={17}
              onChange={handleMessageText}
            />
          </Grid>
        </Grid>
        <Grid
          mt={4}
          pb={8}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Button
            type="submit"
            sx={{
              backgroundColor: theme.palette.primary.main,
              paddingRight: 5,
              paddingLeft: 5,
              "&:hover": { backgroundColor: theme.palette.primary.dark },
            }}
            color="secondary"
            endIcon={<SendIcon />}
          >
            Envoyer
          </Button>
        </Grid>
      </form>
      {openSnackBar && (
        <CustomizedSnackbars
          open={openSnackBar}
          onClose={handleSnackBarClose}
          severity={severitySnackBar}
          errorMessage={errorMessage}
        />
      )}
    </Container>
  );
};

export default FormContact;
