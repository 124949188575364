import { Grid, Typography, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme/MuiTheme";
import image1 from "../../images/home/DSC_3045-Modifier.jpg";
import image2 from "../../images/home/Belise.jpg";
import image3 from "../../images/home/1679381296336.jpg";
import image4 from "../../images/home/image4.svg";
import MainTitle from "../../components/mainTitle/index";

const Home = () => {
  const matches = useMediaQuery("(max-width:1441px)");

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: 32,
          gap: matches ? 32 : 0,
        }}
      >
        <div>
          <img
            src={image1}
            width={512}
            height={342}
            alt="image1"
            style={{ borderRadius: 10 }}
          />
        </div>
        <div>
          <img
            src={image2}
            width={378}
            height={567}
            alt="image2"
            style={{ borderRadius: 10, marginBottom: 8 }}
          />
          <MainTitle text="Créations vêtements d’époque" fontSize={20} />
        </div>
        <div>
          <img
            src={image3}
            width={512}
            height={342}
            alt="image3"
            style={{ borderRadius: 10 }}
          />
        </div>
      </div>
      <Grid
        container
        mt={5}
        mb={8}
        justifyContent="space-around"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.secondary.main,
          boxShadow: "0px 3px 6px " + theme.palette.primary.dark,
        }}
      >
        <Grid item xs={12} sm={6}>
          <img src={image4} width="100%" height={233.5} alt="image4" />
        </Grid>
        <Grid item xs={12} sm={6} padding={5}>
          <Typography fontSize={16} lineHeight={1.7} marginBottom={1}>
            Encore aujourd'hui, au cœur d’un petit village d'Occitanie, au creux
            des collines, se trouve un atelier enchanté nommé "Féeries couture".
          </Typography>
          <Typography fontSize={16} lineHeight={1.7} marginBottom={1}>
            Cet atelier est celui de Clémentine, et sa passion pour l'art de la
            couture a le pouvoir de donner vie aux rêves merveilleux . Son
            atelier peut sembler bien banal si vous n'y prêtez pas attention, et
            pourtant…
          </Typography>
          <Typography fontSize={16} lineHeight={1.7} marginBottom={1}>
            Elle transforme les mots, les idées, les fantasmes, en de
            magnifiques croquis. Le rêve prend doucement forme. Les tissus
            qu'elle sélectionne sont imprégnés de magie. Les étoffes,
            scintillantes comme des éclats de fée, sont ornées de motifs
            enchantés inspirés des légendes de sorcières, d'elfes et de nymphes.
          </Typography>
          <Typography fontSize={16} lineHeight={1.7} marginBottom={1}>
            Dans son atelier mystérieux, Clémentine connaît tous les secrets des
            lutins couturiers, des fées brodeuses et des sorcières teinturières.
            Elle assemble et transforme fils et tissus pour en faire des robes
            extraordinaires, des costumes de mariés à tomber. Le rêve prend vie.
            Chaque tenue a ainsi une histoire à raconter, un secret à révéler,
            telle est la magie de Féeries Couture.
          </Typography>
          <Typography fontSize={16} lineHeight={1.7} marginBottom={1}>
            Les futurs époux qui font appel à Clémentine savent qu'ils vont
            vivre un moment enchanté. Revêtir ces créations magiques, c'est se
            transformer en personnage légendaire, libre, enfin, d'écrire sa
            propre histoire.
          </Typography>
          <Typography fontSize={16} lineHeight={1.7} marginBottom={1}>
            Atours et ornements sont décrits comme des œuvres d'art, le
            merveilleux prend vie. Ainsi, il est dit que Féeries Couture lie le
            monde réel et le monde fantastique. La légende de Clémentine et de
            son atelier enchanté se propage, et le rêve devient une réalité pour
            tous ceux qui passent sa porte.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        alignContent={"center"}
        borderRadius={10}
        direction={"column"}
      >
        <Stack>
          <Typography align="center" variant="h3" mb={1} color={"primary"}>
            Evènement
          </Typography>
        </Stack>
        <Typography align="center" variant="h5" mb={4}>
          Shooting d'inspiration - Premier Empire
        </Typography>
        <video controls width="50%" style={{ borderRadius: 10 }}>
          <source
            src={
              "https://firebasestorage.googleapis.com/v0/b/feeries-couture.appspot.com/o/videos%2FVF_premier_empire_corrige%CC%81e.mp4?alt=media&token=51a84ed0-1149-4300-8468-4d1747794ff1"
            }
            type="video/mp4"
          />
        </video>
      </Grid>
    </>
  );
};

export default Home;
