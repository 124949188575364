import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import MainTitle from "../../components/mainTitle/index";
import firstPic from "../../images/undraw/undraw_Fashion_blogging_re_fhi5.png";
import secondPic from "../../images/undraw/undraw_web_shopping_re_owap.png";
import thirdPic from "../../images/undraw/undraw_Window_shopping_re_0kbm.png";
import artiste from "../../images/artiste/artiste.svg";
import artiste2 from "../../images/artiste/artiste2.svg";
import theme from "../../theme/MuiTheme";

const Artist = () => {
  const pictures = React.useMemo(
    () => [
      { path: firstPic, alt: "listen", subTitle: "A votre écoute" },
      { path: secondPic, alt: "shopping", subTitle: "Vente ou location" },
      { path: thirdPic, alt: "advice", subTitle: "Conseil sur mesure" },
    ],
    []
  );
  return (
    <Grid mt={8} mb={4} sx={{ height: "200%" }}>
      <MainTitle text="L'artiste" fontSize={50} />
      <Grid container justifyContent={"space-evenly"}>
        {pictures.map((picture, index) => (
          <Stack key={index} alignItems={"center"}>
            <img
              width={340}
              height={240}
              src={picture.path}
              alt={picture.alt}
            />
            <Typography variant="h5" color={theme.palette.primary.main}>
              {picture.subTitle}
            </Typography>
          </Stack>
        ))}
      </Grid>
      <Container maxWidth="lg" sx={{ marginTop: 8, borderRadius: 10 }}>
        <Grid container height={388} justifyContent={"center"} mb={8}>
          <Grid item xs={12} md={6}>
            <img width={"100%"} height="100%" src={artiste} alt="artiste" />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: 1,
            }}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
              height={"100%"}
              spacing={2}
            >
              <Typography variant="h4" color={theme.palette.primary.light}>
                Clémentine Ruamps
              </Typography>
              <Typography variant="h6" color={theme.palette.primary.light}>
                Créatrice de merveilles
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container height={388}>
          <Grid item xs={12} md={6} pr={8}>
            <Typography variant="h4" color={theme.palette.primary.main}>
              Mon histoire
            </Typography>
            <Divider
              style={{
                borderWidth: 2,
                width: 80,
                marginTop: 8,
                marginBottom: 16,
              }}
            />
            <Typography fontSize={16} lineHeight={1.7} marginBottom={1}>
              Je suis couturière, spécialisée dans les métiers du spectacle.
              J'ai passé mon BEP (métier de la mode et industrie connexe) sur
              Toulouse ainsi que mon DTMS (Diplôme Technicien Métier du
              Spectacle).
            </Typography>
            <Typography fontSize={16} lineHeight={1.7} marginBottom={1}>
              Quelques mois après l’obtention de mon diplôme, j’ai eu
              l'opportunité de collaborer avec L'association M.A.M.E.T qui
              organise les{" "}
              <a href="https://feteshistoriquesmirepoix.eu" target="blank">
                Fêtes Historiques de Mirepoix
              </a>
              . Depuis plusieurs années, j'ai un rôle de costumière au sein de
              celle-ci et ai en charge l’entretien de plus de 3000 costumes
              ainsi que la création des nouvelles pièces.
            </Typography>
            <Typography fontSize={16} lineHeight={1.7}>
              Après de nombreuses hésitations, j'ai créé mon atelier de
              confection à mon domicile. Je réalise des costumes historiques et
              fantastiques sur mesure ou en petites séries. Je travaille autant
              pour les professionnels du spectacle que pour les particuliers.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img width={"100%"} height="100%" src={artiste2} alt="artiste2" />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Artist;
